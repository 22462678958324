import { CAttribute, CWorkTitle, EncodedKey, DecodedKey } from "@smartdevis/server/src/domainCatalog"
import { CatalogRef, NPKCatalogs } from "@smartdevis/server/src/domain"
import { i18n } from "../services/translations"
import { mkMultiselectSchema } from "../components/forms/formSchemas"
import { FormSchema } from "@smartdevis/forms/src"
import { arrify } from "@smartdevis/utils/src/array"
import { TMap, getFromBrandedMap, toMap, keys, filterObject, SMap, remap, hasField } from "@smartdevis/utils/src/map"
import { identity } from "@smartdevis/utils/src/misc"
import { isEmpty, isObject } from "@smartdevis/utils/src/validators"
import { IdLite } from "@smartdevis/utils/src/id"

export type AttributeSchema = {
    key: string
    visible: boolean
    title: string
    value?: EncodedKey[]
    options: EncodedKey[]
}

export const mkAttributeSchema = (key: string, delta: Partial<AttributeSchema> = {}): AttributeSchema => ({
    key,
    visible: true,
    options: [],
    title: "",
    value: [],
    ...delta
})
export const mkAttributeSchemaFromNode = (
    a: CAttribute,
    visible: boolean,
    selectedAttributes: TMap<EncodedKey, EncodedKey[]>
) => ({
    key: a.key,
    visible,
    title: a.title,
    options: a.children?.map(mkEncodedKey) || [],
    value: getFromBrandedMap(selectedAttributes, mkEncodedKey(a))
})

export const mkNPKAttributeSchemaFromNode = (
    a: NPKCatalogs.NPKVariable,
    visible: boolean,
    selectedAttributes: TMap<EncodedKey, EncodedKey[]>
) => ({
    key: a.number,
    visible,
    title: a.description,
    description: a.description ,
    options: [],
    value: getFromBrandedMap(selectedAttributes, mkEncodedKey(a)),
})

const getChildrenAttributes = (selected: TMap<EncodedKey, EncodedKey[]>, attr: CAttribute): AttributeSchema[] =>
    (attr.children || [])
        .map(cv =>
            getAttributesSchema(
                cv.children || [],
                selected,
                getFromBrandedMap(selected, mkEncodedKey(attr))?.includes(mkEncodedKey(cv)) || false
            )
        )
        .flat()

const getNPKChildrenAttributes = (selected: TMap<EncodedKey, EncodedKey[]>, attr: NPKCatalogs.NPKVariable): AttributeSchema[] =>
    // (attr.variables?.npkvariable || [])
    //     .map(cv =>
            getNPKAttributesSchema(
                [],
                selected,
                getFromBrandedMap(selected, mkNPKEncodedKey(attr))?.includes(mkNPKEncodedKey(attr)) || false
             )
        // )
        // .flat()

export const getAttributesSchema = (
    availableAttributes: CAttribute[] = [],
    selectedAttributes: TMap<EncodedKey, EncodedKey[]> = {},
    visible = true
) =>
    availableAttributes.flatMap(a => [
        mkAttributeSchemaFromNode(a, visible, selectedAttributes),
        ...getChildrenAttributes(selectedAttributes, a)
    ])

export const getNPKAttributesSchema = (
    availableAttributes: NPKCatalogs.NPKVariable[] = [],
    selectedAttributes: TMap<EncodedKey, EncodedKey[]> = {},
    visible = true
) =>
    availableAttributes.flatMap(a => [
        mkNPKAttributeSchemaFromNode(a, visible, selectedAttributes),
        ...getNPKChildrenAttributes(selectedAttributes, a)
    ])

export const getWorkTitleSchema = (
    wt: CWorkTitle,
    selectedAttributes: TMap<EncodedKey, EncodedKey[]> = {}
): AttributeSchema[] => getAttributesSchema(wt.children || [], selectedAttributes)

export const getNPKWorkTitleSchema = (
    wt: NPKCatalogs.NPKNode,
    selectedAttributes: TMap<EncodedKey, EncodedKey[]> = {}
): AttributeSchema[] => getNPKAttributesSchema(wt.variables?.npkvariable || [], selectedAttributes)

export const buildOrder = (nodes: CAttribute[] = []): EncodedKey[] =>
    nodes.reduce<EncodedKey[]>(
        (acc, curr) => [
            ...acc,
            mkEncodedKey(curr),
            ...(curr.children || []).reduce<EncodedKey[]>((_acc, _curr) => [..._acc, ...buildOrder(_curr.children)], [])
        ],
        []
    )

export const mkCatalogRef = (
    catalogId: IdLite,
    workTitle: CWorkTitle,
    attributes: TMap<EncodedKey, EncodedKey[]> = {}
): CatalogRef => ({
    catalogId,
    workTitleKey: workTitle.key,
    attributes,
    order: buildOrder(workTitle.children)
})

export const mkNPKCatalogRef = (
    catalogId: IdLite,
    workTitle: NPKCatalogs.NPKNode,
    attributes: TMap<EncodedKey, EncodedKey[]> = {}
): CatalogRef => ({
    catalogId,
    workTitleKey: workTitle.number,
    attributes,
    order: buildNPKOrder(workTitle.subNodes?.npknode)
})


export const buildNPKOrder = (nodes: NPKCatalogs.NPKNode[] = []): EncodedKey[] =>
    nodes.reduce<EncodedKey[]>(
        (acc, curr) => [
            ...acc,
            mkEncodedKey(curr),
            ...(curr.subNodes?.npknode || []).reduce<EncodedKey[]>((_acc, _curr) => [..._acc, ...buildNPKOrder(_curr.subNodes?.npknode)], [])
        ],
        []
    )


export const mkAttributesFormSchema = (vs: AttributeSchema[]): FormSchema<TMap<EncodedKey, EncodedKey[]>> =>
    toMap(vs, mkEncodedKey, v => {
        if (!v.visible) return { type: "hidden" }
        return mkMultiselectSchema(
            v.title,
            v.options.map(o => [mkDecodedKey(o).title, o]),
            {
                placeholder: isEmpty(v.options)
                    ? i18n("Choose available options or add in your own")
                    : i18n("Add your own option"),
                value: v.value ? arrify(v.value) : [],
                creatable: true
            }
        )
    })

const unitAttrRegexp = new RegExp(/[Ll][Ee]\s*=\s*/g)

export const splitAttributes = (workTitle: CWorkTitle | null, attributeValues: TMap<EncodedKey, EncodedKey[]>) => {
    const allAttrs = workTitle ? getWorkTitleSchema(workTitle, attributeValues) : null
    const unitAttr = allAttrs?.find(attr => unitAttrRegexp.test(attr.title))
    const attrs = allAttrs?.filter(attr => attr.title !== unitAttr?.title)
    const unitOptions = unitAttr ? toMap(unitAttr.options, mkDecodedKeyTitle, mkDecodedKeyTitle) : undefined
    return { attrs, unitOptions }
}

export const splitNPKAttributes = (workTitle: NPKCatalogs.NPKNode | null, attributeValues: TMap<EncodedKey, EncodedKey[]>) => {
    const allAttrs = workTitle ? getNPKWorkTitleSchema(workTitle, attributeValues) : null
    const unitAttr = allAttrs?.find(attr => unitAttrRegexp.test(attr.title))
    const attrs = allAttrs?.filter(attr => attr.title !== unitAttr?.title)
    const unitOptions = unitAttr ? toMap(unitAttr.options, mkDecodedKeyTitle, mkDecodedKeyTitle) : undefined
    return { attrs, unitOptions }
}

export const prepareAttributesToDisplay = ({
    attributes,
    order = []
}: Pick<CatalogRef, "attributes" | "order">): [string, string][] => {
    const allKeys = keys(attributes)
    const ordered = order.filter(key => allKeys.includes(key))
    const unordered = allKeys.filter(key => !order.includes(key))
    return [...ordered, ...unordered]
        .filter(k => getFromBrandedMap(attributes, k).length)
        .map(k => [mkDecodedKeyTitle(k), arrify(getFromBrandedMap(attributes, k)).map(mkDecodedKeyTitle).join(", ")])
}

export const filterUnusedAttributes = (
    workTitle: CWorkTitle | null,
    attributeValues: TMap<EncodedKey, EncodedKey[]>
) => {
    const { attrs } = splitAttributes(workTitle, attributeValues)
    return filterObject(attributeValues, k => attrs?.find(attr => mkEncodedKey(attr) === k)?.visible || false)
}
export const filterUnusedNPKAttributes = (
    workTitle: NPKCatalogs.NPKNode | null,
    attributeValues: TMap<EncodedKey, EncodedKey[]>
) => {
    const { attrs } = splitNPKAttributes(workTitle, attributeValues)
    return filterObject(attributeValues, k => attrs?.find(attr => mkEncodedKey(attr) === k)?.visible || false)
}

export const toAttributesMap = (schemas: SMap<AttributeSchema>): TMap<EncodedKey, EncodedKey[]> =>
    remap(
        schemas,
        (k, s) => (isEncodedKey(k) ? k : mkEncodedKey(s)),
        s => s.value || []
    )

export const fromAttributesMap = (vs: TMap<EncodedKey, EncodedKey[]>): SMap<AttributeSchema> =>
    remap<EncodedKey[], AttributeSchema>(vs, identity, (values, attribute) =>
        mkAttributeSchema(attribute, {
            title: mkDecodedKeyTitle(attribute),
            value: values,
            options: values
        })
    )

export const isEncodedKey = (v: string | EncodedKey): v is EncodedKey => {
    try {
        const result = JSON.parse(atob(v))
        return isObject(result) && hasField(result, "title")
    } catch (e) {
        return false
    }
}

export const mkDecodedKey = (encoded: string | EncodedKey): DecodedKey => {
    if (!isEncodedKey(encoded)) return { title: encoded }
    const res = JSON.parse(atob(encoded))
    return res.key ? { ...res } : { title: res.title }
}
export const mkDecodedKeyTitle = (encoded: string | EncodedKey) => mkDecodedKey(encoded).title

export const mkEncodedKey = (d: DecodedKey) =>
    btoa(JSON.stringify(d.key ? { key: d.key, title: d.title } : { title: d.title })) as EncodedKey

export const mkNPKEncodedKey = (d: NPKCatalogs.NPKVariable) =>
    btoa(JSON.stringify(d.number ? { key: d.number, title: `${d.number} ${d.description}` } : { title: `${d.number} ${d.description}` })) as EncodedKey
