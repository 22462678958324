import { runValidatorsRaw, validateMemberOf, ValidationMap } from "@smartdevis/utils/src/validators"
import { isOk } from "@smartdevis/utils/src/result"
import { clientEnv } from "../env"

export type FeatureFlags = Pick<clientEnv, "feature_show_deductions_calculator" | "feature_show_catalog_positions" | "feature_show_crb_catalog_positions">

const visibility = [validateMemberOf(["show", "hide"])]

const envValidationMap: ValidationMap<Required<FeatureFlags>> = {
    feature_show_deductions_calculator: visibility,
    feature_show_catalog_positions: visibility,
    feature_show_crb_catalog_positions: visibility
}

export const getFeatureFlag = <T extends keyof FeatureFlags>(env: FeatureFlags, key: T): FeatureFlags[T] | null => {
    const flagValue = env[key]
    const v = runValidatorsRaw(envValidationMap[key], flagValue)
    if (isOk(v)) return flagValue
    return null
}
