import { LicenseUnlockInfo, LicenceInfo, LicenceIDs, SubscribedWorkStations, } from "@smartdevis/server/src/crbNpkCatalogs"
import { mkReducer } from "./utils"
import { createAction } from "@smartdevis/utils/src/actions"
import { Async,mkNotFetched } from "@smartdevis/utils/src/async"
import { NPKCatalogs } from "@smartdevis/server/src/domain"
import { Login, fetchLicenseIds, fetchNPKChapterDetails, fetchNPKChapterVersions, fetchNPKChapters, fetchNPKLanguages, fetchNPKUnits, fetchNPKVersionYears, fetchSubscribedWorkstations } from "./dataQueries/licenseDirectory"
import { SMap } from "@smartdevis/utils/src/map"

export type CrbNpkCatalogsState = {
    CRBAccessAuth: Async<NPKCatalogs.AccessStatus>
    NPKChapters: Async<NPKCatalogs.Chapters>
    NPKLanguages: Async<NPKCatalogs.Languages>
    NPKLicenseIds: Async<NPKCatalogs.LicenseIds>
    NPKVersionYears: Async<NPKCatalogs.VersionYears>
    NPKChapterVersions: SMap<Async<NPKCatalogs.NPKChapterVersion[]>>
    NPKChapterDetails: Async<NPKCatalogs.NPKChapter>
    NPKUnits: Async<NPKCatalogs.NPKUnits[]>

    LicenseUnlockInfo: Async<LicenseUnlockInfo>
    LicenceInfo: Async<LicenceInfo>
    LicenceIDs: Async<LicenceIDs>
    SubscribedWorkStations: Async<SubscribedWorkStations>
}

export const initialState: CrbNpkCatalogsState = {
    CRBAccessAuth: mkNotFetched(),
    NPKChapters: mkNotFetched(),
    NPKLanguages: mkNotFetched(),
    NPKLicenseIds: mkNotFetched(),
    NPKVersionYears: mkNotFetched(),
    NPKUnits: mkNotFetched(),
    NPKChapterVersions: {},
    NPKChapterDetails: mkNotFetched(),
    LicenseUnlockInfo: mkNotFetched(),
    LicenceInfo: mkNotFetched(),
    LicenceIDs: mkNotFetched(),
    SubscribedWorkStations: mkNotFetched()
}

export const actions = {
    _setCRBAccessAuth: (p: Async<NPKCatalogs.AccessStatus>) => createAction("_setCRBAccessAuth", p),
    _setNPKLanguages: (p: Async<NPKCatalogs.Languages>) => createAction("_setNPKLanguages", p),
    _setNPKVersionYears: (p: Async<NPKCatalogs.VersionYears>) => createAction("_setNPKVersionYears", p),
    _setLicenseIds: (p: Async<NPKCatalogs.LicenseIds>) => createAction("_setLicenseIds", p),

    _setNPKChapters: (p: Async<NPKCatalogs.Chapters>) => createAction("_setNPKChapters", p),
    _setNPKChapterVersions: (chapter: string, versions: Async<NPKCatalogs.NPKChapterVersion[]>) => createAction("_setNPKChapterVersions", { chapter, versions }),
    _setNPKChapterDetails: (collection: Async<NPKCatalogs.NPKChapter>) => createAction("_setNPKChapterDetails", collection),
    _setNPKUnits: (units: Async<NPKCatalogs.NPKUnits[]>) => createAction("_setNPKUnits", units),

    logon: (payload: LicenseUnlockRequestPayload) => createAction("logon", payload),
    fetchNPKLanguages: () => createAction("fetchNPKLanguages"),
    fetchNPKVersionYears: () => createAction("fetchNPKVersionYears"),

    fetchNPKChapters: (p: LicenseUnlockRequestPayload & NPKCatalogs.Language) => createAction("fetchNPKChapters", p),
    fetchNPKChapterVersions: (p: NPKCatalogs.NPKChapterVersionRequest) => createAction("fetchNPKChapterVersions", p),
    fetchNPKChapterDetails: (p: NPKCatalogs.NPKChapterVersionRequest & { year: string }) => createAction("fetchNPKChapterDetails", p),
    fetchNPKUnits: (p: NPKCatalogs.NPKUnitsRequest) => createAction("fetchNPKUnits", p),

    _flushState: () => createAction("_flushState"),
    _setCatalogsRes: <T extends keyof CrbNpkCatalogsState>(field: T, v: CrbNpkCatalogsState[T]) => createAction("_setCatalogsRes", { [field]: v }),
    _setLicenseUnlockInfo: (licenseUnlockInfo: Async<LicenseUnlockInfo>) => createAction("_setLicenseUnlockInfo", { licenseUnlockInfo }),
    _setLicenseInfo: (licenseInfo: Async<LicenceInfo>) => createAction("_setLicenseInfo", { licenseInfo }),
    // _setLicenseIds: (licenseIds: Async<LicenceIDs>) => createAction("_setLicenseIds", { licenseIds }),
    _setSubscribedWorkStations: (workstations: Async<SubscribedWorkStations>) => createAction("_setSubscribedWorkStations", { workstations }),
    fetchLicenseUnlockInfo: (p: any) => createAction("fetchLicenseUnlockInfo", p),
    fetchLicenseInfo: (p: any) => createAction("fetchLicenseInfo", p),
    fetchLicenseIds: (p: any) => createAction("fetchLicenseIds", p),
    fetchSubscribedWorkstations: (p: Pick<LicenseUnlockRequestPayload,'customerID' | 'licenseID'>) => createAction("fetchSubscribedWorkstations", p)
}

export const reducer = mkReducer<CrbNpkCatalogsState>((state, action, ctx) => {
    switch (action.type) {

        case "_setCRBAccessAuth":
            return { CRBAccessAuth: action.payload }
        case "_setNPKLanguages":
            return { NPKLanguages: action.payload }
        case "_setNPKVersionYears":
            return { NPKVersionYears: action.payload }
        case "_setNPKChapters":
            return { NPKChapters: action.payload }
        case "_setLicenseIds":
            return { NPKLicenseIds: action.payload }
        case "_setNPKChapterDetails":
            return { NPKChapterDetails: action.payload }
        case "_setNPKUnits":
            return { NPKUnits: action.payload }
        case "logon":
            return Login(ctx.auth, action.payload)
        case "fetchNPKLanguages":
            return fetchNPKLanguages(ctx.auth)
        case "fetchNPKVersionYears":
            return fetchNPKVersionYears(ctx.auth)
        case "fetchLicenseIds":
            return fetchLicenseIds(ctx.auth, action.payload)
        case "fetchNPKChapters":
            return fetchNPKChapters(ctx.auth, action.payload)
        case "fetchNPKChapterVersions":
            return fetchNPKChapterVersions(ctx.auth, action.payload)
        case "fetchNPKChapterDetails":
            return fetchNPKChapterDetails(ctx.auth, action.payload)
        case "fetchNPKUnits":
            return fetchNPKUnits(ctx.auth, action.payload)



        case "_setCatalogsRes":
            return action.payload
        case "_flushState":
            return initialState
        case "_setLicenseUnlockInfo":
            return { ...state, LicenseUnlockInfo: action.payload.licenseUnlockInfo }
        case "_setLicenseInfo":
            return { ...state, getLicenceInfo: action.payload.licenseInfo }
        case "_setSubscribedWorkStations":
            return { getSubscribedWorkStations: action.payload.workstations }
        case "fetchSubscribedWorkstations":
            return fetchSubscribedWorkstations(ctx.auth, action.payload);
    }
})

export type LicenseUnlockRequestPayload = {
    customerID: string,
    licenseID: string,
    workStationID: string,
    userId: string
}
